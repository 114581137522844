import React from 'react'
import AboutUsComponent from '../components/pageComponents/aboutUsComponent'
import Wrapper from '../components/wrapper'

const AboutPage = ({ location }) => {
  return (
    <Wrapper location={location} title="About | MedReps"
      description="Discover more about MedReps and what sets us apart as the go-to-site for medical sales professionals and employers.">
      <AboutUsComponent />
    </Wrapper>
  )
}
export default AboutPage
