import React from 'react'
const AboutUsComponent = () => {
  return (
    <>
      <div className="w-full py-20" id="age-experience">
        <div className="container mx-auto">
          <div className="w-4/5 mx-auto">
            <h1 className="font-playfair font-normal text-[50px] text-center">
              About MedReps
            </h1>
            <p className="font-sans text-base text-center">
              MedReps is the go-to-site for the medical sales community. We
              connect experienced medical sales professionals with top jobs and
              provide high quality, industry-specific career advice. We provide
              medical device, medtech and pharmaceutical organizations the tools
              to attract and source quality medical sales talent.
            </p>
            <p className="font-sans text-base mb-0 text-center">
              For 20 years, we’ve been successful largely due to word-of-mouth
              and referrals – from medical sales reps, employers and recruiters.
              We are a small company with a big vision - to create the best
              possible experience for the medical sales community that supports
              their growth and goals.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default AboutUsComponent
